<template>
  <div>
    <NotFound />
  </div>
</template>

<script>
import NotFound from '@/components/404/404.vue'

export default {
  components: {
    NotFound,
  },
}
</script>

<style>

</style>
