<template>
  <div>
    <NotAuth />
  </div>
</template>

<script>
import NotAuth from '@/components/404/401.vue'

export default {
  components: {
    NotAuth,
  },
}
</script>

<style>

</style>
