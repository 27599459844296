<script>
/* eslint-disable */
</script>
<template>
  <div class="container-fluid pt-2 position-relative">
    <div class="d-flex align-items-center justify-content-between custom-navbar">
      <VSIcons
        name="VerdeSysGreen"
        width="200"
        class="logo"
      />
      <BButton
        variant="outline-primary"
        class="d-flex align-items-center"
      >
        Criar uma conta
        <VSIcons
          name="ArrowRightGreen"
          width="200"
          class="ml-2"
        />
      </BButton>
    </div>
    <div class="content d-flex flex-column align-items-center justify-content-center position-relative">
      <b-img
        :src="require('@/assets/images/verde-sys-big.png')"
        alt=""
        class="decoration"
      />
      <h1>404</h1>
      <h2 class="d-block mx-auto">
        Ops, algo está errado
      </h2>
      <p> A página que você está procurando pode nunca ter existido,
        mas você pode tentar novamente clicando no botão abaixo. </p>
      <BButton
        v-if="$store.state.auth.isUserLoggedIn()"
        variant="primary"
        class="d-flex align-items-center justify-content-center mt-4"
        @click="checkPermissionPlanRoutes()"
      >
        <VSIcons
          name="SlantedArrow"
          class="mr-2"
          width="9"
        />
        {{ buttonName }}
      </BButton>
      <BButton
        v-else
        variant="primary"
        class="d-flex align-items-center justify-content-center mt-4"
        @click="$router.push({ name: 'auth' })"
      >
        <VSIcons
          name="SlantedArrow"
          class="mr-2"
          width="9"
        />
        Voltar para login
      </BButton>
    </div>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'

export default {
  components: {
    VSIcons,
    BButton,
    BImg,
  },
  data() {
    return {
      buttonName: '',
      isActive: null,
    }
  },
  computed: { ...mapGetters({ getUserData: 'auth/getUserData', permission: 'auth/getUserPermissions' }) },
  mounted() {
    this.checkPermissionPlanActive();
    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => {
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    checkPermissionPlanActive() {
      const buttonName = this.isActive ? 'Voltar para dashboard' : 'Voltar para minha conta'
      this.buttonName = buttonName
    },
    checkPermissionPlanRoutes() {
      if (this.isActive === true || this.permission.name === 'Master') {
        this.$router.push('/dashboard')
      } else {
        this.$router.push({ name: 'my-account' })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.custom-navbar {
  height: 10vh;
}
.decoration {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100vw;
  pointer-events: none;
  transform: translateX(.5%) translateY(-8%);
}
.content {
  height: 88vh;
  overflow: hidden;
  h1 {
  font-weight: bold;
  font-size: 144px;
  line-height: 174px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
  }
  h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  color: #33383C;
  }
  p {
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #7D8E9E;
  max-width: 432px;
  margin-top: 13px;
  @media (max-width: 575.98px) {
    padding: 0 10px;
  }
  }
}

</style>
