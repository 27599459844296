<template>
  <company />
</template>

<script>
import Company from '@/modules/company/Company.vue'

export default {
  components: { Company },
}
</script>
