<template>
  <div><Enterprise /></div>
</template>

<script>
import Enterprise from '@/modules/enterprise/Enterprise.vue'

export default {
  components: {
    Enterprise,
  },
}
</script>

<style>

</style>
