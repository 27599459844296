<script>
/* eslint-disable */
</script>
<template>
  <div class="container-fluid pt-2 position-relative">
    <div class="d-flex align-items-center justify-content-between custom-navbar">
      <VSIcons
        name="LogoVerdeSys"
        color-primary="#1BAD82"
        color-secondary="#076E5F"
        width="140"
        height="50"
      />
    </div>
    <div class="content d-flex flex-column align-items-center justify-content-center position-relative">
      <b-img
        :src="require('@/assets/images/verde-sys-big.png')"
        alt=""
        class="decoration"
      />
      <h1>401</h1>
      <h2 class="d-block mx-auto">
        Você não tem acesso a essa página.
      </h2>
      <p v-if="$store.state.auth.isUserLoggedIn()">
        A página que você está tentando acessar não está autorizada para seu usário.
      </p>
      <p v-else>
        Você precisar estar logado na plataforma para acessar essa página <router-link :to="{name: 'auth'}">
          clique aqui
        </router-link> e faça o login.
      </p>
      <BButton
        v-if="$store.state.auth.isUserLoggedIn()"
        variant="primary"
        class="d-flex align-items-center justify-content-center mt-4"
        @click="checkPermissionPlanRoutes()"
      >
        <VSIcons
          name="SlantedArrow"
          class="mr-2"
          width="9"
        />
        {{ buttonName }}
      </BButton>
      <BButton
        v-else
        variant="primary"
        class="d-flex align-items-center justify-content-center mt-4"
        @click="$router.push({ name: 'auth' })"
      >
        <VSIcons
          name="SlantedArrow"
          class="mr-2"
          width="9"
        />
        Voltar para login
      </BButton>
    </div>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import VSIcons from '@/assets/icons/VSIcons.vue'
import api from '@/libs/axios'

export default {
  components: {
    VSIcons,
    BButton,
    BImg,
  },
  data() {
    return {
      buttonName: '',
      isActive: null,
    }
  },
  computed: { ...mapGetters({ getUserData: 'auth/getUserData', permission: 'auth/getUserPermissions' }) },
  mounted() {
    this.checkPermissionPlanActive();
    const userIdAccount = JSON.parse(localStorage.getItem('id_account_verdesys'));
    api.get(`/accounts/${userIdAccount}`).then((response) => {
        this.isActive = response.data.isActive;
    });
  },
  methods: {
    checkPermissionPlanActive() {
      const buttonName = this.isActive ? 'Voltar para dashboard' : 'Voltar para minha conta'
      this.buttonName = buttonName
    },
    checkPermissionPlanRoutes() {
      if (this.isActive === true || this.permission.name === 'Master') {
        this.$router.push({ name: 'my-account' })
      } else {
        this.$router.push({ name: 'my-account' })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
.custom-navbar {
  height: 10vh;
}
.decoration {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100vw;
  pointer-events: none;
  transform: translateX(.5%) translateY(-8%);
}
.content {
  height: 88vh;
  overflow: hidden;
  h1 {
  font-weight: bold;
  font-size: 144px;
  line-height: 174px;
  text-align: center;
  letter-spacing: 0.75px;
  color: #33383C;
  }
  h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  text-align: center;
  color: #33383C;
  }
  p {
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: #7D8E9E;
  max-width: 432px;
  margin-top: 13px;
  @media (max-width: 575.98px) {
    padding: 0 10px;
  }
  }
}

</style>
